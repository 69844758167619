import React from 'react'
import ReactPlayer from 'react-player/lazy'
import { Box } from '@chakra-ui/react'

const SilentPlayer = ({url, width="100%", height="100w", mt}) => {
   const preventClick = (e) => e.preventDefault()
    return (
        <Box  onContextMenu={preventClick} >
        <ReactPlayer style={{marginTop:`${mt}`}}url={url}  
          
        playsinline={true}
        autoplay={true}
        muted={true}
        loop={true}
        playing={true}
        width='100vw'
        height='100w'
        controls={false} width={width} height={height} />
        </Box>
    )
}

export default SilentPlayer
